<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <stats-card header-color="indigo">
          <template slot="header">
            <div class="card-icon" @click="menuVisible = !menuVisible">
              <md-icon v-if="!load">coronavirus</md-icon>
              <i class="md-icon md-icon-font md-theme-default" v-if="load">
                <md-progress-spinner
                  :md-diameter="30"
                  :md-stroke="3"
                  md-mode="indeterminate"
                ></md-progress-spinner>
              </i>
            </div>
          </template>
          <template slot="content">
            <md-app>
              <md-app-toolbar class="md-primary" style="padding: 4px;">
                <span class="md-title">{{ title }}</span>

                <md-button
                  style="margin: 3px 6px;"
                  v-for="dataset in datasets"
                  :key="dataset.id"
                  class="md-white md-raised"
                >
                  <md-switch
                    style="margin: 0 16px;"
                    v-model="year"
                    :value="dataset.year"
                    >{{ dataset.year }}
                  </md-switch>
                </md-button>

                <md-button class="md-raised md-accent" @click="downloadMap">
                  Download PNG
                </md-button>
                <a id="image-download" download="map.png"></a>

                <div class="md-toolbar-section-end">
                  <md-button
                    class="md-icon-button md-primary"
                    @click="toggleMenu"
                    v-if="!menuVisible"
                  >
                    <md-icon>menu</md-icon>
                  </md-button>
                </div>
              </md-app-toolbar>

              <md-app-drawer
                :md-active.sync="menuVisible"
                md-persistent="full"
                md-right
                md-swipeable
              >
                <md-toolbar class="md-transparent" md-elevation="0">
                  <md-button
                    class="md-raised md-success"
                    style="margin: 0 3px; min-height: 48px; width:80%;"
                    @click="generateMap"
                  >
                    <md-icon style="min-width: 28px;">insights</md-icon>
                    Gerar Mapa
                  </md-button>

                  <div class="md-toolbar-section-end">
                    <md-button
                      style="margin: 0 3px; min-height: 48px;"
                      class="md-icon-button md-dense md-primary"
                      @click="toggleMenu"
                    >
                      <md-icon>keyboard_arrow_left</md-icon>
                    </md-button>
                  </div>
                </md-toolbar>

                <div class="full-control">
                  <div class="list">
                    <md-table v-model="actions" md-card>
                      <md-table-row
                        slot="md-table-row"
                        slot-scope="{ item }"
                        md-selectable="single"
                      >
                        <md-table-cell md-label="Ação" md-sort-by="action">
                          <md-switch
                            v-model="actionSelected"
                            :value="item.action"
                          ></md-switch>
                        </md-table-cell>
                        <md-table-cell md-label="Opção" md-sort-by="text">{{
                          item.text
                        }}</md-table-cell>
                      </md-table-row>
                    </md-table>
                  </div>
                </div>
              </md-app-drawer>

              <md-app-content>
                <div id="map"></div>
                <md-card
                  md-with-hover
                  id="legend"
                  class="ol-legend"
                  style="display: none"
                >
                  <md-ripple>
                    <md-card-content style="padding: 0; ">
                      <div
                        class="md-content md-table-content md-scrollbar md-theme-default"
                      >
                        <table id="legend-table"></table>
                      </div>
                    </md-card-content>
                  </md-ripple>
                </md-card>
                <div id="popup" class="ol-popup">
                  <a href="#" id="popup-closer" class="ol-popup-closer"></a>
                  <div id="popup-content"></div>
                </div>
              </md-app-content>
            </md-app>
          </template>

          <template slot="footer">
            <div class="stats"></div>
          </template>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import "ol/ol.css";
//import Circle from "ol/geom/Circle";
//import Feature from "ol/Feature";w
//import { fromLonLat } from "ol/proj";
import hexToRgba from "hex-to-rgba";
import Overlay from "ol/Overlay";
import GeoJSON from "ol/format/GeoJSON";
import Map from "ol/Map";
import View from "ol/View";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
import { OSM, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { ScaleLine, Control } from "ol/control";

import { StatsCard } from "@/components";

export default {
  components: {
    StatsCard
  },
  async created() {
    this.load = true;
    this.source = this.$route.params.source;
    this.datasetName = this.$route.params.datasetName;
    this.type_date = this.$route.params.type;
    this.colorCases = this.$colorCases;
    this.colorDeaths = this.$colorDeaths;

    this.getDataSets();
    this.generateDefaultStyles();
  },
  async mounted() {
    await this.getLayerMap();
    let type_date = "";
    let filter = "";

    if (this.type_date == "region") {
      type_date = "Regiões";
      this.zoom = 4.8;
      this.center = [-48.0073978, -15.721751];
    } else if (this.type_date == "state") {
      type_date = "Estados";
      this.zoom = 5.8;
      this.center = [-45.0691055, -9.6620932];
    } else if (this.type_date == "city") {
      type_date = "Cidades";
      this.zoom = 7.1;
      this.center = [-43.0691055, -6.8420932];
    } else if (this.type_date == "mesoregion") {
      type_date = "Mesoregiões";
      this.zoom = 7.1;
      this.center = [-43.0691055, -6.8420932];
    } else if (this.type_date == "microregion") {
      type_date = "Microrregiões";
      this.zoom = 7.1;
      this.center = [-43.0691055, -6.8420932];
    }

    this.title = `Mapa de ${this.datasetName.toUpperCase()} ${filter} por ${type_date} Fonte: ${this.source.toUpperCase()}`;

    this.popup.container = document.getElementById("popup");
    this.popup.content = document.getElementById("popup-content");
    this.popup.closer = document.getElementById("popup-closer");

    this.legend.div = document.getElementById("legend");
    this.legend.table = document.getElementById("legend-table");

    this.subtitlePainel = new Control({
      element: this.legend.div
    });

    this.markerPopup = new Overlay({
      element: this.popup.container,
      autoPan: true,
      autoPanAnimation: { duration: 250 }
    });

    this.popup.closer.onclick = () => {
      this.markerPopup.setPosition(undefined);
      this.popup.closer.blur();
      return false;
    };

    await this.createMap();
    await this.setEventMap();

    this.featureOverlay = new VectorLayer({
      source: new VectorSource(),
      map: this.map,
      style: feature => {
        this.highlightStyle.getText().setText(feature.get("name"));
        return this.highlightStyle;
      }
    });
    this.load = false;
  },
  data() {
    return {
      map: null,
      zoom: 4.8,
      center: [-48.0073978, -15.721751],
      actionSelected: null,
      datasetsSelected: null,
      datasets: [],
      year: null,
      allCases: [],
      allDeaths: [],
      colorCases: null,
      menuVisible: true,
      load: false,
      casesColor: null,
      title: "",
      image_circle: new CircleStyle({
        radius: 5,
        fill: null,
        stroke: new Stroke({ color: "red", width: 1 })
      }),
      style: null,
      highlightStyle: null,
      featureOverlay: null,
      vectorSource: null,
      vectorLayer: null,
      pixel: null,
      highlight: null,
      markerPopup: null,
      popup: {
        container: null,
        content: null,
        closer: null
      },
      legend: {
        div: null,
        table: null
      },
      actions: [
        {
          action: "all_cases",
          text: "Total casos"
        },
        {
          action: "all_deaths",
          text: "Total Óbito"
        }
      ]
    };
  },
  props: {},
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    async getDataSets() {
      try {
        let result = await Vue.$http.get(
          `v1/dataset/${this.source}/${this.datasetName}?limit=5`
        );
        this.datasets = await result.data;
        this.year = await this.datasets[0].year;
        this.datasetsSelected = await this.datasets[0];
        /*         this.datasets.forEach(dataset => {
          this.allCases[dataset.year] = null;
        }); */
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    async generateMap() {
      if (this.actionSelected == "all_cases") {
        this.getAllCases();
      } else if (this.actionSelected == "all_deaths") {
        this.getAllDeaths();
      }
      return true;
    },
    styleFunction(feature) {
      this.style.getText().setText(feature.get("name"));
      //return this.styles[feature.getGeometry().gettype_date()];
      return this.style;
    },
    async getLayerMap() {
      try {
        let result = await Vue.$http.get(`v1/${this.type_date}?type=map`);
        let regions = result.data;

        let geojsonObject = {
          type: "FeatureCollection",
          crs: { type: "name", properties: { name: "EPSG:4326" } },

          features: []
        };

        regions.forEach(state => {
          let data = {
            type: "Feature",
            geometry: JSON.parse(state.geoJson),
            id: state.id,
            properties: {
              name: state.name,
              value: ""
            }
          };

          geojsonObject.features.push(data);
        });

        this.vectorSource = new VectorSource({
          features: new GeoJSON().readFeatures(geojsonObject)
        });
        this.vectorLayer = new VectorLayer({
          source: this.vectorSource,
          style: this.styleFunction
        });
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    async displayFeatureInfo(pixel) {
      this.vectorLayer.getFeatures(pixel).then(features => {
        let feature = features.length ? features[0] : undefined;

        if (feature !== this.highlight) {
          if (this.highlight) {
            this.featureOverlay.getSource().removeFeature(this.highlight);
          }
          if (feature) {
            this.featureOverlay.getSource().addFeature(feature);
          }
          this.highlight = feature;
        }
      });
    },
    async displayPopupInfo(evt) {
      let coordinate = evt.coordinate;

      this.vectorLayer.getFeatures(evt.pixel).then(features => {
        let feature = features.length ? features[0] : undefined;
        if (features.length) {
          this.markerPopup.setPosition(coordinate);
          console.log(feature.getProperties().value);
          this.popup.content.innerHTML =
            feature.getId() +
            ": " +
            feature.get("name") +
            "&nbsp;" +
            feature.getProperties().value;
        }
      });
    },
    async createMap() {
      this.map = new Map({
        target: "map",
        layers: [new TileLayer({ source: new OSM() }), this.vectorLayer],
        overlays: [this.markerPopup],
        view: new View({
          projection: "EPSG:4326",
          center: this.center,
          zoom: this.zoom
        })
      });
      this.map.addControl(new ScaleLine({ units: "metric" }));
      this.map.addControl(this.subtitlePainel);
    },
    async setEventMap() {
      this.map.on("pointermove", evt => {
        if (evt.dragging) {
          return;
        }
        this.pixel = this.map.getEventPixel(evt.originalEvent); //return
        this.displayFeatureInfo(this.pixel);
      });
      this.map.on("click", evt => {
        this.displayPopupInfo(evt);
        this.displayFeatureInfo(evt.pixel);
      });
    },
    async getAllCases() {
      this.load = true;
      this.legend.div.style.display = "block";
      this.createLegend(this.colorCases);

      try {
        if (this.allCases[this.year] == null) {
          let result = await Vue.$http.get(
            `v1/${this.type_date}/${this.source}/${this.datasetName}/${this.year}?type=map&filter_map=all_cases`
          );
          this.allCases[this.year] = result.data;
        }
        let totalCases = 0;
        this.allCases[this.year].forEach(region => {
          totalCases += region.countCases;
        });

        this.allCases[this.year].forEach(region => {
          let percentage = region.countCases / (totalCases / 100);
          let feature = this.vectorLayer.getSource().getFeatureById(region.id);
          feature.setProperties({
            value: region.countCases.toLocaleString("pt-BR")
          });

          if (percentage > 0 && percentage < 5) {
            feature.setStyle(
              this.generateStyle(
                this.colorCases.cases_5,
                `${region.name}: ${region.countCases.toLocaleString("pt-BR")}`
              )
            );
          } else if (percentage >= 5 && percentage < 10) {
            feature.setStyle(
              this.generateStyle(
                this.colorCases.cases_10,
                `${region.name}: ${region.countCases.toLocaleString("pt-BR")}`
              )
            );
          } else if (percentage >= 10 && percentage < 20) {
            feature.setStyle(
              this.generateStyle(
                this.colorCases.cases_20,
                `${region.name}: ${region.countCases.toLocaleString("pt-BR")}`
              )
            );
          } else if (percentage >= 20 && percentage < 30) {
            feature.setStyle(
              this.generateStyle(
                this.colorCases.cases_30,
                `${region.name}: ${region.countCases.toLocaleString("pt-BR")}`
              )
            );
          } else if (percentage >= 30 && percentage < 40) {
            feature.setStyle(
              this.generateStyle(
                this.colorCases.cases_40,
                `${region.name}: ${region.countCases.toLocaleString("pt-BR")}`
              )
            );
          } else if (percentage >= 40) {
            feature.setStyle(
              this.generateStyle(
                this.colorCases.cases_50,
                `${region.name}: ${region.countCases.toLocaleString("pt-BR")}`
              )
            );
          }
        });

        this.load = false;
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
        this.load = false;
      }

      this.load = false;
    },
    async getAllDeaths() {
      this.load = true;
      this.legend.div.style.display = "block";
      this.createLegend(this.colorDeaths);

      try {
        if (this.allDeaths[this.year] == null) {
          let result = await Vue.$http.get(
            `v1/${this.type_date}/${this.source}/${this.datasetName}/${this.year}?type=map&filter_map=all_deaths`
          );
          this.allDeaths[this.year] = result.data;
        }
        let totalCases = 0;
        this.allDeaths[this.year].forEach(region => {
          totalCases += region.countCases;
        });
        this.allDeaths[this.year].forEach(region => {
          let percentage = region.countCases / (totalCases / 100);
          let feature = this.vectorLayer.getSource().getFeatureById(region.id);
          feature.setProperties({
            value: region.countCases.toLocaleString("pt-BR")
          });
          if (percentage > 0 && percentage < 5) {
            feature.setStyle(
              this.generateStyle(
                this.colorDeaths.cases_5,
                `${region.name}: ${region.countCases.toLocaleString("pt-BR")}`
              )
            );
          } else if (percentage >= 5 && percentage < 10) {
            feature.setStyle(
              this.generateStyle(
                this.colorDeaths.cases_10,
                `${region.name}: ${region.countCases.toLocaleString("pt-BR")}`
              )
            );
          } else if (percentage >= 10 && percentage < 20) {
            feature.setStyle(
              this.generateStyle(
                this.colorDeaths.cases_20,
                `${region.name}: ${region.countCases.toLocaleString("pt-BR")}`
              )
            );
          } else if (percentage >= 20 && percentage < 30) {
            feature.setStyle(
              this.generateStyle(
                this.colorDeaths.cases_30,
                `${region.name}: ${region.countCases.toLocaleString("pt-BR")}`
              )
            );
          } else if (percentage >= 30 && percentage < 40) {
            feature.setStyle(
              this.generateStyle(
                this.colorDeaths.cases_40,
                `${region.name}: ${region.countCases.toLocaleString("pt-BR")}`
              )
            );
          } else if (percentage >= 40) {
            feature.setStyle(
              this.generateStyle(
                this.colorDeaths.cases_50,
                `${region.name}: ${region.countCases.toLocaleString("pt-BR")}`
              )
            );
          }
        });
        this.load = false;
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
        this.load = false;
      }

      this.load = false;
    },
    generateDefaultStyles() {
      this.style = new Style({
        stroke: new Stroke({ color: "rgba(139,195,74, 1)", width: 1 }),
        fill: new Fill({ color: "rgba(139,195,74, 0.4)" }),
        text: new Text({
          font: "12px Calibri,sans-serif",
          fill: new Fill({ color: "#000" }),
          stroke: new Stroke({ color: "#fff", width: 3 })
        })
      });

      this.highlightStyle = new Style({
        stroke: new Stroke({ color: "rgba(51,105,30, 0.1)", width: 1 }),
        fill: new Fill({ color: "rgba(51,105,30, 0.4)" }),
        text: new Text({
          font: "12px Calibri,sans-serif",
          fill: new Fill({ color: "#000" }),
          stroke: new Stroke({ color: "#fff", width: 3 })
        })
      });
    },
    generateStyle(color, text) {
      let style = new Style({
        stroke: new Stroke({
          color: "rgba(128, 128, 128, 1)",
          width: 1
        }),
        fill: new Fill({ color: hexToRgba(color, 0.8) }),
        text: new Text({
          text: text,
          font: "12px Calibri,sans-serif",
          fill: new Fill({ color: "#000" }),
          stroke: new Stroke({ color: "#fff", width: 3 })
        })
      });
      return style;
    },
    createLegend(colors) {
      this.legend.table.innerHTML = "";

      let tbody = document.createElement("tbody");
      tbody.style.display = "table";
      tbody.style.width = "120px";

      let tr_5 = document.createElement("tr");
      tr_5.classList.add("md-table-row");
      tr_5.classList.add("md-has-selection");

      let tr_10 = document.createElement("tr");
      tr_10.classList.add("md-table-row");
      tr_10.classList.add("md-has-selection");

      let tr_20 = document.createElement("tr");
      tr_20.classList.add("md-table-row");
      tr_20.classList.add("md-has-selection");

      let tr_30 = document.createElement("tr");
      tr_30.classList.add("md-table-row");
      tr_30.classList.add("md-has-selection");

      let tr_40 = document.createElement("tr");
      tr_40.classList.add("md-table-row");
      tr_40.classList.add("md-has-selection");

      let tr_50 = document.createElement("tr");
      tr_50.classList.add("md-table-row");
      tr_50.classList.add("md-has-selection");

      let td_5 = document.createElement("td");
      td_5.classList.add("md-table-cell");
      td_5.innerHTML = "< 5%";
      td_5.style.backgroundColor = hexToRgba(colors.cases_5, 0.8);
      tr_5.appendChild(td_5);

      let td_10 = document.createElement("td");
      td_10.classList.add("md-table-cell");
      td_10.innerHTML = "=> 5% e < 10%";
      td_10.style.backgroundColor = hexToRgba(colors.cases_10, 0.8);
      tr_10.appendChild(td_10);

      let td_20 = document.createElement("td");
      td_20.classList.add("md-table-cell");
      td_20.innerHTML = "=> 10% e < 20%";
      td_20.style.backgroundColor = hexToRgba(colors.cases_20, 0.8);
      tr_20.appendChild(td_20);

      let td_30 = document.createElement("td");
      td_30.classList.add("md-table-cell");
      td_30.innerHTML = "=> 20% e < 30%";
      td_30.style.backgroundColor = hexToRgba(colors.cases_30, 0.8);
      tr_30.appendChild(td_30);

      let td_40 = document.createElement("td");
      td_40.classList.add("md-table-cell");
      td_40.innerHTML = "=> 30% e < 40%";
      td_40.style.backgroundColor = hexToRgba(colors.cases_40, 0.8);
      tr_40.appendChild(td_40);

      let td_50 = document.createElement("td");
      td_50.classList.add("md-table-cell");
      td_50.innerHTML = "=> 40%";
      td_50.style.backgroundColor = hexToRgba(colors.cases_50, 0.8);
      tr_50.appendChild(td_50);

      tbody.appendChild(tr_5);
      tbody.appendChild(tr_10);
      tbody.appendChild(tr_20);
      tbody.appendChild(tr_30);
      tbody.appendChild(tr_40);
      tbody.appendChild(tr_50);

      this.legend.table.appendChild(tbody);
    },
    downloadMap() {
      this.map.once("rendercomplete", () => {
        let mapCanvas = document.createElement("canvas");
        let size = this.map.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        let mapContext = mapCanvas.getContext("2d");
        Array.prototype_date.forEach.call(
          document.querySelectorAll(".ol-layer canvas"),
          canvas => {
            if (canvas.width > 0) {
              let opacity = canvas.parentNode.style.opacity;
              mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
              let transform = canvas.style.transform;
              let matrix = transform
                // eslint-disable-next-line no-useless-escape
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(",")
                .map(Number);
              CanvasRenderingContext2D.prototype_date.setTransform.apply(
                mapContext,
                matrix
              );
              mapContext.drawImage(canvas, 0, 0);
            }
          }
        );
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(mapCanvas.msToBlob(), "map.png");
        } else {
          let link = document.getElementById("image-download");
          link.href = mapCanvas.toDataURL();
          link.click();
        }
      });
      this.map.renderSync();
    }
  }
};
</script>
<style lang="scss">
#map {
  width: 100%;
  height: 800px;
}
.md-drawer {
  max-width: 360px;
}
ul div.md-card {
  margin: 0;
}

.md-checkbox {
  margin: 0 16px !important;
}

.container-checkbox {
  background-color: #ffffff;
}

.md-table.md-theme-default .md-table-head {
  padding: 5px;
  margin: 5px;
}

.md-table.md-theme-default .md-table-head:first-child {
  max-width: 35px;
  padding: 0;
  margin: 5px;
  text-align: left;
}

.md-table.md-theme-default .md-table-row td {
  max-width: 35px;
  padding: 0;
  margin: 5px;
  text-align: left;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 180px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 1px;
}
.ol-popup-closer:after {
  content: "✖";
}

@media only screen and (max-width: 959px) {
  .ol-legend {
    display: none !important;
  }
}

.ol-legend {
  margin-right: 10px !important;
  margin-top: 10px !important;
  margin-left: auto !important;
  padding: 0;
  width: 124px;
  font-size: 14px;
  text-shadow: 0 0 20px #fff, 0 -10px 20px #fff, 0 10px 20px #fff,
    -20px 0 40px #fff, 20px 0 40px #fff !important;
  font-weight: 600;
}
</style>
